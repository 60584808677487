#global-loader {
  height: 100dvh;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
}

#global-loader.auth {
  height: calc(100dvh - 190px);
}

.grid-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: relative;
  width: 64px;
  height: 60px;
}

.loader::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  background: #364FB3;
  width: 64px;
  height: 32px;
  border-radius: 0 0 50px 50px;
  animation: move 0.5s linear infinite alternate;
}

.loader::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  background: #ff6900;
  width: 24px;
  height: 24px;
  transform: translateX(-50%) rotate(0deg);
  animation: rotate 2s linear infinite;
}

.loading-text::after {
  content: "Loading...";
  font-size: 24px;
  animation: loadingAnimation 2s linear infinite;
}

.bar-icon {
  width: 31px;
  flex-direction: column;
  cursor: pointer;
}

.bar-icon span {
  background-color: #AAF40C;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
  margin-bottom: 5px;
}

.bar-icon span:nth-child(2) {
  width: 20px;
}

.bar-icon span:nth-child(3) {
  width: 16px;
  margin-bottom: 0;
}

.grid-center {
  display: flex;
  align-items: center;
  justify-content: center;
}


.countryCodeSelector-list .MuiPaper-root {
  height: 350px;
}

@keyframes loadingAnimation {
  0% {
    content: "Loading...";
  }

  25% {
    content: "Loading";
  }

  50% {
    content: "Loading.";
  }

  75% {
    content: "Loading..";
  }
}

@keyframes rotate {
  100% {
    transform: translateX(-50%) rotate(360deg)
  }
}

@keyframes move {
  0% {
    transform: rotate(10deg)
  }

  100% {
    transform: rotate(-10deg)
  }
}

@keyframes fly {
  0% {
    top: 0%;
    left: 0%;
  }

  75% {
    top: 50%;
    left: 50%;
  }

  100% {
    top: 51%;
    left: 100%;
  }
}

@-webkit-keyframes fly {
  0% {
    top: 0%;
    left: 0%;
  }

  75% {
    top: 50%;
    left: 50%;
  }

  100% {
    top: 51%;
    left: 100%;
  }
}